import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 188 188" {...props}>
      <rect width="188" height="188" rx="36" fill="#335DE5" />
      <path
        d="M106.19 101.479C96.5251 107.544 83.5279 110.743 70.3307 110.077C59.2664 109.544 50.2016 105.478 44.7361 98.8792C39.9371 92.9471 38.0709 85.1488 39.3373 76.1507C39.7372 73.1513 40.6703 70.2853 41.9367 67.5525L42.1367 67.1526C54.734 42.6911 82.7281 30.627 108.856 38.492C134.984 46.357 151.913 71.9516 149.181 99.4124C146.448 126.873 124.852 148.535 97.6582 151.001L98.2581 158C128.785 155.267 153.046 130.939 156.113 100.079C159.179 69.2188 140.116 40.5583 110.789 31.6935C81.4617 22.8287 50.0017 36.4258 35.938 63.9533L35.6714 64.4199C34.0717 67.8191 32.9387 71.4184 32.4721 75.0843C30.9391 86.0153 33.2719 95.8132 39.4039 103.278C46.0692 111.477 56.9336 116.342 69.9974 117.009C85.8607 117.809 101.657 113.409 112.855 105.411L106.19 101.479Z"
        fill="white"
      />
      <path
        d="M120.714 110.543C114.382 115.742 99.6523 125.14 75.2575 126.406C47.9299 127.806 36.5324 119.474 36.4657 119.341L32 124.407C32.4666 124.807 43.3976 133.138 69.0588 133.138C71.125 133.138 73.3912 133.138 75.6574 132.938C105.184 131.405 121.381 119.274 127.046 114.076L120.714 110.543Z"
        fill="white"
      />
      <path
        d="M132.246 117.408C128.447 122.274 123.782 126.34 118.516 129.539C99.8534 141.27 76.1251 142.803 59.5286 141.936L59.1953 148.802C61.8614 148.935 64.5942 149.001 67.2603 149.001C114.117 149.001 133.046 128.206 138.379 120.807L132.246 117.408Z"
        fill="white"
      />
      <path
        d="M136.042 84.5483C136.042 87.6809 133.509 90.2137 130.376 90.2137C127.244 90.2137 124.711 87.6809 124.711 84.5483C124.711 81.4156 127.244 78.8828 130.376 78.8828C133.509 78.8828 136.042 81.4156 136.042 84.5483Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
